import { Grid, Skeleton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useStyles } from './JobSkeleton.styles';

export interface ListSkeletonProps {
    searchBar?: boolean;
}

export function JobSkeleton() {
    const { classes } = useStyles();

    return (
        <Grid className={classes.dataGridContainer}>
            <Grid
                container
                flexDirection="column"
                flexWrap="nowrap"
                className={classes.generalListContainer}
            >
                <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    marginBottom="16px"
                    wrap="nowrap"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Skeleton
                            sx={{
                                height: '40px',
                                width: '460px',
                                backgroundColor: '#F2F2F2',
                                transform: 'none',
                                borderRadius: '0',
                            }}
                        />
                    </Grid>
                    <Grid item paddingLeft="0px !important">
                        <Skeleton
                            sx={{
                                height: '40px',
                                width: '132px',
                                backgroundColor: '#F2F2F2',
                                transform: 'none',
                                borderRadius: '0',
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid className={classes.generalListHeader} />
                <Grid className={classes.generalListContentContainer}>
                    {Array(100)
                        .fill(true)
                        .map((_, idx) => (
                            <Skeleton
                                key={uuidv4()}
                                variant="rectangular"
                                animation="wave"
                                className={classes.generalListItem}
                                sx={{ backgroundColor: `${idx % 2 === 0 ? '#F9F9F9' : '#FFFFFF'}` }} // Alternates row colour
                            />
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
